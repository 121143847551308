import React, { useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import RoleRecap from "./RoleRecap";
import UserCompanies from "./UserCompanies";
import UserPrivileges from "./UserPrivileges";
import UserRoleSelect from "./UserRoleSelect";

const UserRoleItem = ({
  role,
  roles,
  setRoles,
  companies,
  ROLES,
  PRIVILEGES,
  PRIVILEGE_CATEGORIES,
  user,
  roleConfig,
  editRoleId,
  setEditRoleId,
  removeRole,
  saveRole,
  isLoading,
  userIsMine,
  fetchId,
}) => {
  const [confirmRemove, setConfirmRemove] = useState(false);

  const validateRole = () => {
    if (role.clients.length) {
      if (user.id && fetchId != "create") {
        saveRole();
      } else {
        setEditRoleId(false);
      }
    } else {
      toast.warning(
        "Veuillez assigner au moins une société à ce rôle avant de l'enregistrer."
      );
    }
  };

  const deleteRole = () => {
    if (!confirmRemove) {
      setConfirmRemove(true);
    } else {
      setConfirmRemove(false);
      removeRole();
    }
  };

  return editRoleId == role.id ? (
    <>
      <UserRoleSelect
        PRIVILEGES={PRIVILEGES}
        ROLES={ROLES}
        role={role}
        roles={roles}
        setRoles={setRoles}
        user={user}
        roleConfig={roleConfig}
      />
      {role.roleKey.length ? (
        <UserCompanies
          role={role}
          roles={roles}
          setRoles={setRoles}
          companies={companies}
        />
      ) : null}
      {role.roleKey.length > 0 && role.clients.length > 0 ? (
        <>
          <UserPrivileges
            PRIVILEGES={PRIVILEGES}
            roleConfig={roleConfig}
            setRoles={setRoles}
            roles={roles}
            role={role}
          />
          <div className="d-flex justify-content-center align-items-center flex-column mt-3 w-100">
            {isLoading ? (
              <Loader size={30} />
            ) : (
              <>
                <button
                  className="btn btn-info btn-sm mb-3"
                  onClick={validateRole}
                >
                  {(role.id + "").length < 24
                    ? "Enregistrer le rôle"
                    : "Enregistrer le rôle"}
                </button>
                <button
                  className="btn btn-link text-danger btn-sm"
                  onClick={deleteRole}
                >
                  {confirmRemove
                    ? "Êtes-vous certain•e ?"
                    : "Supprimer le rôle"}
                </button>
              </>
            )}
          </div>
        </>
      ) : null}
    </>
  ) : (
    <>
      <RoleRecap
        role={role}
        user={user}
        setEditRoleId={setEditRoleId}
        ROLES={ROLES}
      />
    </>
  );
};

export default UserRoleItem;
