import { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import { DateTime } from "luxon";

const usePaymentFormQuittances = ({ client, quittances, setPaymentOk }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPaymentIntent, setIsLoadingPaymentIntent] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(false);
  const [pmErrors, setPmErrors] = useState({});
  const [paymentErrors, setPaymentErrors] = useState({});
  const [piErrors, setPiErrors] = useState({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [modePayment, setModePayment] = useState(false);
  const [hasSavedPaymentMethods, setHasSavedPaymentMethods] = useState(false);
  const [displayPaymentForm, setDisplayPaymentForm] = useState(false);
  const [modeP, setModeP] = useState();
  const [paymentIntentGenerationData, setPaymentIntentGenerationData] =
    useState();

  useEffect(() => {
    getPaymentMethods();
    //setDisplayPaymentForm(true);
  }, []);

  const getPaymentMethods = () => {
    setIsLoading(true);

    dataService.get(
      `clients/${client.id}/stripe/paymentmethods`,
      setPaymentMethods,
      setPmErrors,
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    if (paymentMethods) {
      if (paymentMethods?.length > 0) {
        setHasSavedPaymentMethods(true);
        document.querySelector(".payment-method-module").scrollIntoView();
        const defaultPM = paymentMethods.find(
          (d) =>
            d.isDefault && DateTime.fromISO(d.expirationDate) > DateTime.local()
        );
        if (defaultPM) onSelectSavedPaymentMethod(defaultPM.paymentMethodId);
      } else {
        setHasSavedPaymentMethods(false);
        setDisplayPaymentForm(true);
      }
    }
  }, [paymentMethods]);

  const getPaymentIntent = (modeP, paymentMethodId) => {
    setIsLoadingPaymentIntent(true);
    const paymentIntentData = {
      paiementData: {
        avenantType: "RENEW",
        typeMethodePaiement: modeP,
        methodePaiementId: paymentMethodId || null,
      },
      quittancesIds: quittances.map((q) => q.id),
    };
    dataService.post(
      "payments/payment-intent/receipts",
      paymentIntentData,
      (data) => {
        setPaymentIntent(data);
        setPaymentIntentGenerationData({
          ...paymentIntentData,
          paiementData: {
            ...paymentIntentData.paiementData,
            intentionPaiementId: data.paymentIntentId,
          },
        });
      },
      setPiErrors,
      () => setIsLoadingPaymentIntent(false)
    );
  };

  const onChangeNewModePayment = (mode) => {
    setModeP(mode);
    getPaymentIntent(mode);
  };

  const onSelectSavedPaymentMethod = (paymentMethodId) => {
    if (paymentMethodId) {
      setPiErrors({});
      setSelectedPaymentMethod(paymentMethodId);
      const methodRef = paymentMethods.find(
        (p) => p.paymentMethodId == paymentMethodId
      );
      var modeP = methodRef.type;
      setModePayment(modeP);
      getPaymentIntent(modeP, paymentMethodId);
    }
  };

  const toggleNexPaymentForm = (isOpen) => {
    setDisplayPaymentForm(isOpen);
    if (isOpen) {
      setModeP(null);
    }
    if (!isOpen && selectedPaymentMethod) {
      setSelectedPaymentMethod(null);
    }
  };

  const onValidatePayement = () => {
    dataService.post(
      `receipts/mines/pay`,
      {
        ...paymentIntentGenerationData,
      },
      () => {
        setPaymentOk({ ...paymentIntentGenerationData, modePayment });
      }
    );
  };

  return {
    isLoading,
    isLoadingPaymentIntent,
    paymentMethods,
    pmErrors,
    paymentErrors,
    piErrors,
    selectedPaymentMethod,
    paymentIntent,
    isPaying,
    modePayment,
    hasSavedPaymentMethods,
    displayPaymentForm,
    modeP,
    getPaymentMethods,
    getPaymentIntent,
    onChangeNewModePayment,
    onSelectSavedPaymentMethod,
    toggleNexPaymentForm,
    setPaymentIntent,
    setPmErrors,
    setIsPaying,
    setPaymentErrors,
    onValidatePayement,
  };
};

export default usePaymentFormQuittances;
