import { useEffect, useState } from "react";
import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import { Link } from "react-router-dom";

const useContractRenewAll = () => {
  const [state, dispatch] = useStore();
  const modal_actions = modalActions(state, dispatch);
  const [isLoading, setIsLoading] = useState(false);
  const [quittances, setQuittances] = useState([]);
  const [selectedClientToPay, setSelectedClientToPay] = useState(null);

  const getAllRenewableContracts = async () => {
    setIsLoading(true);
    dataService.get(
      `receipts/mines/to-pay`,
      setQuittances,
      (err) => {},
      () => setIsLoading(false)
    );
  };

  const getContractsByClient = () => {
    const contractsByClient = {};
    /* Wanted structure :
    {
      "client1_id": {
        client: {client1},
        contrats: [contrat1, contrat2, ...]
      },
      "client2_id": {
        client: {client2},
        contrats: [contrat3, contrat4, ...]
      }
    }

    Each contrat must has the following structure :
    {
      id: "contrat_id",
      nomProduit: "nomProduit"
      numero: "numero",
      titre: "titre",
      objet: "objet",
      compagnie: "compagnie",
      quittances: [
        {
          id: "quittance_id",
          debutPeriode: "debutPeriode",
          dateLimite: "dateLimite",
          restantDu: "restantDu",
      },
      ...
    ]

    }

    */

    quittances.forEach((quittance) => {
      const clientId = quittance.client.id;

      if (!contractsByClient[clientId]) {
        contractsByClient[clientId] = {
          client: quittance.client,
          contrats: [],
        };
      }

      const contract = {
        id: quittance.contrat.id,
        typeProduit: quittance.contrat.typeProduit,
        numero: quittance.contrat.numero,
        titre: quittance.contrat.titre,
        objet: quittance.contrat.objet,
        compagnie: quittance.contrat.compagnie,
        quittances: [],
      };

      const existingContract = contractsByClient[clientId].contrats.find(
        (c) => c.id === contract.id
      );

      if (!existingContract) {
        contractsByClient[clientId].contrats.push(contract);
      }

      const quittanceToAdd = {
        id: quittance.id,
        debutPeriode: quittance.debutPeriode,
        dateLimite: quittance.dateLimite,
        finPeriode: quittance.finPeriode,
        restantDu: quittance.restantDu,
        isPaid: quittance.isPaid,
      };

      (existingContract || contract).quittances.push(quittanceToAdd);
    });

    return contractsByClient;
  };

  const onSelectClientToPay = (client, selectedQuittances) => {
    setSelectedClientToPay({ ...client, quittances: selectedQuittances });
  };

  const onDeselectClientToPay = () => {
    setSelectedClientToPay(null);
  };

  const onPaidQuittances = (quittancesIds, modePayment) => {
    setQuittances([
      ...quittances.map((q) => {
        return quittancesIds.includes(q.id) ? { ...q, isPaid: true } : q;
      }),
    ]);
    onDeselectClientToPay();
    openSuccessPaymentModal(modePayment);
  };

  const openSuccessPaymentModal = (modePayment) => {
    modal_actions.updateModal({
      isOpen: true,
      showCloseButtonSeconds: 5,
      content: (
        <div className="font-weight-bold text-center text-info mb-4">
          {modePayment === "CB" && (
            <div>Votre paiement par CB a été accepté.</div>
          )}
          {modePayment === "SEPA" && (
            <div className="mb-2">
              Votre prélèvement SEPA <br />
              est en cours de traitement
              <br /> auprès de votre banque.
            </div>
          )}
          <i className="animated zoomIn faster fa fa-check-circle fa-4x my-3"></i>
          <br />
          {modePayment === "CB" && (
            <p className="font-weight-normal text-dark text-sm">
              Retrouvez votre facture sur la page{" "}
              <Link
                onClick={() =>
                  modal_actions.updateModal({ isOpen: false, content: null })
                }
                to="/mes-factures"
              >
                Mes factures
              </Link>
              .
            </p>
          )}
          {modePayment === "SEPA" && (
            <p className="font-weight-normal text-dark text-sm">
              Votre facture sera disponible dès la réception des fonds par
              Perceval. Habituellement 3 à 5 jours.
            </p>
          )}
        </div>
      ),
      containerStyle: {
        maxWidth: 450,
      },
    });
  };

  return {
    isLoading,
    selectedClientToPay,
    quittances,
    clients: getContractsByClient(),
    getAllRenewableContracts,
    onSelectClientToPay,
    onDeselectClientToPay,
    onPaidQuittances,
  };
};

export default useContractRenewAll;

/*
quittance array example : 

[
    {
        "id": "6412ff6b7ed73e001d069254",
        "modulrId": "14373",
        "restantDu": 463,
        "restantDu": 0,
        "debutPeriode": "2022-12-31T20:00:00.000Z",
        "dateLimite": "2023-01-15T20:00:00.000Z",
        "createdAt": "2023-09-08T10:06:42.000Z",
        "updatedAt": "2024-05-08T02:10:01.365Z",
        "contrat": {
            "id": "5da444de0fca280017176cd0",
            "numero": "504901-373755",
            "titre": "PJ Professionnelle GoZen +",
            "objet": "Agences de Communication (publicité, photo, salons, congrès, Services d'information…)",
            "compagnie": "SFPJ GROUPAMA PJ",
            "nomProduit": "504901+"
        },
        "client": {
            "id": "5da444de0fca280017176ccf",
            "nomSociete": "10POSITIF"
        }
    },
    {
        "id": "6218b0cf511edd001d9ad7a5",
        "modulrId": "10001",
        "restantDu": 463,
        "restantDu": 0,
        "debutPeriode": "2021-12-31T20:00:00.000Z",
        "dateLimite": "2022-03-15T20:00:00.000Z",
        "createdAt": "2022-02-25T09:47:38.000Z",
        "updatedAt": "2024-05-08T02:10:01.365Z",
        "contrat": {
            "id": "5da444de0fca280017176cd0",
            "numero": "504901-373755",
            "titre": "PJ Professionnelle GoZen +",
            "objet": "Agences de Communication (publicité, photo, salons, congrès, Services d'information…)",
            "compagnie": "SFPJ GROUPAMA PJ",
            "nomProduit": "504901+"
        },
        "client": {
            "id": "5da444de0fca280017176ccf",
            "nomSociete": "10POSITIF"
        }
    },
    {
        "id": "605766165fdf620015fefe2e",
        "modulrId": "6517",
        "restantDu": 463,
        "restantDu": 0,
        "debutPeriode": "2020-12-31T20:00:00.000Z",
        "dateLimite": "2021-03-30T20:00:00.000Z",
        "createdAt": "2021-03-21T13:04:05.000Z",
        "updatedAt": "2024-05-08T02:10:01.365Z",
        "contrat": {
            "id": "5da444de0fca280017176cd0",
            "numero": "504901-373755",
            "titre": "PJ Professionnelle GoZen +",
            "objet": "Agences de Communication (publicité, photo, salons, congrès, Services d'information…)",
            "compagnie": "SFPJ GROUPAMA PJ",
            "nomProduit": "504901+"
        },
        "client": {
            "id": "5da444de0fca280017176ccf",
            "nomSociete": "10POSITIF"
        }
    }
]

*/
