import Axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_URL } from "../../../config";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import OverlayBottom from "../../common/OverlayBottom";
import Page from "../../common/layout/Page";
import ClientListPage from "../ClientListPage/ClientListPage";
import RenewablePolicies from "./RenewablePolicies/RenewablePolicies";
import RenewableQuittances from "./RenewablePolicies/RenewableQuittances";
import SimpleView from "./SimpleView/SimpleView";
import ViewClient from "./ViewClient";
import ViewSwitch from "./ViewSwitch/ViewSwitch";

var strTimeout = null;
const isBOA = false;
const PAGE_WIDTH = 960;

const ClientList = () => {
  const [isInit, setIsInit] = useState(false);
  const [users, setUsers] = useState([]);
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState(false);
  const [search, setSearch] = useState({
    user: {
      searchString: "",
      insCompanies: [],
      products: [],
      productTypes: [],
      accountStatus: "",
      contractStatus: "",
      hasMinOneContract: false,
    },
    page: 1,
  });
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);
  const [overlayData, setOverlayData] = useState(null);
  const [overlayOpen, setOverlayOpen] = useState(false);

  const [isLoadingOverlay, setIsLoadingOverlay] = useState(false);
  const [isSendingMail, setIsSendingMail] = useState(false);
  const [isHighlight, setIsHighlight] = useState(false);
  const [showDetailView, setShowDetailView] = useState(false);

  useEffect(() => {
    getDatas();
  }, []);
  useEffect(() => {
    setIsHighlight(false);
  }, [search.user.searchString]);

  function getDatas() {}

  useEffect(() => {
    if (!isInit) {
      searchClients();
    }
    setIsInit(false);
  }, [search.page]);

  function searchClients() {
    setErrors(false);
    // if (isLoading) return false;
    setIsLoading(true);
    if (isBOA) {
      Axios.post(API_URL + "customers/search", search)
        .then((res) => {
          setUsers(res.data.users);
          setNbResults(res.data.count);
          setNbPages(Math.ceil(parseInt(res.data.count) / 25));
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
          //setUsers([]);
        })
        .then(() => {
          setIsLoading(false);
        });
    } else {
      Axios.get(API_URL + "clients/mines/customers")
        .then((res) => {
          setUsers(res.data);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }
  function openClient(
    user,
    client,
    clientList,
    currentTab = false,
    policyId = false
  ) {
    setOverlayOpen(true);

    if (client) {
      setIsLoadingOverlay(true);
      Axios.get(API_URL + "clients/" + client.id)
        .then((res) => {
          setOverlayData({
            user: res.data.user,
            clientList: clientList,
            client: res.data,
            currentTab: currentTab,
            policyId: policyId,
          });
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsLoadingOverlay(false);
        });
    }
  }
  function closeOverlay(andRefresh = false) {
    setOverlayData(null);
    setOverlayOpen(false);
    if (andRefresh && typeof andRefresh == "boolean") {
      searchClients();
    }
  }

  function updateUsersData(data) {
    var us = JSON.parse(JSON.stringify(users));

    us.find((u) => u.id == data.id).gender = data.gender;
    us.find((u) => u.id == data.id).lastname = data.lastname;
    us.find((u) => u.id == data.id).firstname = data.firstname;
    us.find((u) => u.id == data.id).isActivated = data.isActivated;

    setUsers(us);
    setOverlayData({ ...overlayData, user: data });
  }
  function resendActivationMail(id) {
    if (
      isSendingMail ||
      !window.confirm(
        "Êtes-vous certain•e de vouloir renvoyer l'email d'activation ?"
      )
    )
      return false;
    setIsSendingMail(true);
    window.$(".resendMail").addClass("animated pulse infinite isactive");
    Axios.get(API_URL + "customers/" + id + "/email_activation")
      .then((res) => {
        window.$(".resendMail").removeClass("animated pulse infinite isactive");
        toast.success(
          "Un email d'activation a bien été envoyé à cet utilisateur"
        );
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsSendingMail(false);
      });
  }
  useEffect(() => {
    highlightResults();
  });

  function highlightResults() {
    var strSearch = search.user.searchString.split(" ");
    var accOpened = [];

    window.$(".highlight").each((i, elem) => {
      window.$(elem).removeClass("highlight");
    });

    if (strSearch[0].length > 1) {
      for (var key in strSearch) {
        let str = strSearch[key];

        window.$(".user-name").each((i, elem) => {
          if (window.$(elem).html().toLowerCase().includes(str.toLowerCase())) {
            window.$(elem).addClass("highlight");
          }
        });
        window.$(".user-email").each((i, elem) => {
          if (window.$(elem).html().toLowerCase().includes(str.toLowerCase())) {
            window.$(elem).addClass("highlight");
          }
        });
        window.$(".client-name").each((i, elem) => {
          if (window.$(elem).html().toLowerCase().includes(str.toLowerCase())) {
            window.$(elem).addClass("highlight");
            //window.$("." + window.$(elem).attr('data-ref')).click()
            if (!accOpened.includes(window.$(elem).attr("data-ref"))) {
              accOpened.push(window.$(elem).attr("data-ref"));
            }
          }
        });
        window.$(".client-siret").each((i, elem) => {
          if (window.$(elem).html().toLowerCase().includes(str.toLowerCase())) {
            window.$(elem).addClass("highlight");
            //window.$("." + window.$(elem).attr('data-ref')).click()
            if (!accOpened.includes(window.$(elem).attr("data-ref"))) {
              accOpened.push(window.$(elem).attr("data-ref"));
            }
          }
        });
        window.$(".contract-name").each((i, elem) => {
          if (window.$(elem).html().toLowerCase().includes(str.toLowerCase())) {
            window.$(elem).addClass("highlight");
            //window.$("." + window.$(elem).attr('data-ref')).click()
            if (!accOpened.includes(window.$(elem).attr("data-ref"))) {
              accOpened.push(window.$(elem).attr("data-ref"));
              accOpened.push(window.$(elem).attr("data-reftop"));
            }
          }
        });
      }

      accOpened.forEach((acc) => {
        window.$("#" + acc).collapse("show");
      });
    }
    window.$(".collapse").each((i, elem) => {
      if (!accOpened.includes(window.$(elem).attr("id"))) {
        window.$(elem).collapse("hide");
      }
    });
    setIsHighlight(true);
  }

  return (
    <>
      <Page
        container="container-fluid"
        titleMargin={showDetailView ? "" : "mb-0"}
        margin={showDetailView ? "my-3" : "my-3"}
        title={
          <>
            <div></div>
            <ViewSwitch
              showDetailView={showDetailView}
              setShowDetailView={setShowDetailView}
            />
          </>
        }
        errors={errors}
      >
        <div className="row">
          <div className="col-12 mt-3">
            {errors && errors.other ? (
              <div className="text-danger m-auto text-center">
                {errors.other}
              </div>
            ) : isLoading ? (
              <div style={{ height: 200 }}>
                <Loader size={70} />
              </div>
            ) : (
              <>
                <div
                  className="row"
                  style={{ maxWidth: PAGE_WIDTH, margin: "0 auto" }}
                >
                  <div data-priv="app_paypj">
                    <RenewablePolicies users={users || []} />
                  </div>
                  <div data-priv="app_payreceipt">
                    <RenewableQuittances />
                  </div>
                </div>
                {showDetailView ? (
                  <ClientListPage />
                ) : (
                  // <DetailView
                  //   users={users}
                  //   openClient={openClient}
                  //   resendActivationMail={resendActivationMail}
                  //   isSendingMail={isSendingMail}
                  //   isBOA={isBOA}
                  //   nbPages={nbPages}
                  //   search={search}
                  //   changePage={changePage}
                  // />
                  <div className="mx-auto" style={{ maxWidth: PAGE_WIDTH }}>
                    <SimpleView users={users} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Page>
      <OverlayBottom overlayOpen={overlayOpen} closeOverlay={closeOverlay}>
        {isLoadingOverlay ? (
          <Loader
            withContainer
            containerStyle={{
              position: "absolute",
              left: 0,
            }}
          />
        ) : (
          overlayData && (
            <ViewClient
              datas={overlayData}
              setUsers={setUsers}
              updateUsersData={updateUsersData}
              resendActivationMail={resendActivationMail}
              openClient={openClient}
              isSendingMail={isSendingMail}
              isBOA={isBOA}
              closeOverlay={closeOverlay}
            />
          )
        )}
      </OverlayBottom>
    </>
  );
};

export default ClientList;
